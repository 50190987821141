<template>
  <form class="_form" @submit.prevent="handleSubmit(!v$.$invalid)">
    <label class="p-field mb-4">
      <span class="p-label" v-t="'username'"></span>
      <InputText type="text" v-model="profile.name" />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'email'"></span>
      <InputText type="email" v-model="profile.email" />
      <span class="p-hint">This is your login</span>
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'profile.phone'"></span>
      <InputText type="tel" v-model="profile.phone" />
    </label>

    <button class=" _link" v-t="isShow ? 'cancel' : 'profile.change_password'" @click="isShow = !isShow"></button>

    <div class="_password-box mt-4" v-if="isShow">
      <label class="p-field mb-4">
        <span class="p-label" v-t="'old_password'"></span>
        <InputText type="password" v-model="password.old_password" />
      </label>
      <label class="p-field mb-4">
        <span class="p-label" v-t="'new_password'"></span>
        <InputText type="password" v-model="password.new_password" />
      </label>
      <label class="p-field">
        <span class="p-label" v-t="'replay_new_password'"></span>
        <InputText type="password" v-model="password.replay_new_password" />
      </label>
      <label class="p-field">
        <span class="p-label" v-t="'replay_new_password'"></span>
        <InputText type="password" v-model="password.replay_new_password" />
      </label>
    </div>

    <div class="mt-4" v-if="isBalanceEdit">
      <label class="p-field mb-4">
        <span class="p-label" v-t="'profile.total_credit'"></span>
        <InputText type="text" v-model="balance.credits" />
      </label>
      <label class="p-field">
        <span class="p-label" v-t="'profile.available_tokens'"></span>
        <InputText type="text" v-model="balance.tokens" />
      </label>
    </div>

    <Button class="p-button-big _full-width mt-5" type="submit"><span v-t="'save_change'"></span></Button>
  </form>
</template>

<script>

import { ref, reactive } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {
    InputText,
    Button,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    credits: String,
    tokens: String,
    isBalanceEdit: Boolean
  },
  setup(props) {
    const isShow = ref(false)

    const profile = reactive({
      name: props.name,
      email: props.email,
      phone: props.phone,
    })
    const balance = reactive({
      credits: props.credits,
      tokens: props.tokens
    })
    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: '',
    })



    return {
      isShow,
      password,
      profile,
      balance,
    }
  }
}
</script>

<style lang="scss" module>
  .password {

  }
</style>