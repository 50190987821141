<template>
  <ProfileCard
    :name="data.name"
    :credits="data.credits"
    :tokens="data.tokens"
  />
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="_form _relative">
    <Loader v-if="isLoading" isOverlay />
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label" v-t="'username'"></span>
      <InputText type="text" v-model="v$.name.$model" @blur="v$.name.$touch()" />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label" v-t="'email'"></span>
      <InputText type="email" v-model="v$.email.$model" @blur="v$.email.$touch()" />
      <span class="p-hint">This is your login</span>
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label" v-t="'profile.phone'"></span>
      <InputText type="tel" v-model="v$.phone.$model" @blur="v$.phone.$touch()" />
    </label>

    <label class="p-field mb-4">
      <span class="p-label" v-t="'profile.total_credit'"></span>
      <InputNumber type="text" v-model="data.credits" />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'profile.available_tokens'"></span>
      <InputNumber type="text" v-model="data.tokens" />
    </label>

    <Button class="p-button-big _full-width" type="submit" :disabled="isSubmiting"><span v-t="'profile.add_employee'"></span></Button>
  </form>
</template>

<script>
import { ref, reactive } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex'
import { useVuelidate } from "@vuelidate/core";
import { email, required, minLength } from "@vuelidate/validators";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';

import { Loader } from '../../../components/ui'
import { ProfileCard } from '../../../components/common'

const defaultModel = {
  name: '',
  email: '',
  phone: '',
  credits: '',
  tokens: '',
}

export default {
  components: {
    InputNumber,
    InputText,
    Button,
    ProfileCard,
    Loader,
  },
  setup() {
    const store = useStore()
    const { t } = useI18n()
    const toast = useToast()

    const data = ref({})
    const isLoading = computed(() => store.state.companyUser.isLoading);
    const isError = computed(() => store.state.companyUser.isError);
    const isSubmiting = computed(() => store.state.companyUser.isSubmiting);

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required },
    });

    function reset(params) {
      data.value = defaultModel
      v$.value.$reset()
    }

    const v$ = useVuelidate(rules, data)

    const handleSubmit = (isFormValid) => {
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      store.dispatch('companyUser/sendNew', { body: data.value, reset, t, toast })
    }


    return {
      v$,
      data,
      handleSubmit,
      isLoading,
      isError,
      isSubmiting,
    }
  }
}
</script>
