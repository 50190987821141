<template>
  <Toolbar :class="$style.core" class="mb-5">
    <template #left>
      <div class="flex align-items-center">
        <Avatar size="big" :img="avatarPreview || avatar" />
        <div class="ml-4">
          <h2 class="_heading-2 mb-2" v-if="name">{{name}}</h2>
          <div class="flex">
            <label :class="$style.upload" class="flex align-items-center">
              <input type="file" ref="avatar" @change="setAvatar" :class="$style.uploadInput" >
              <Icon className="_img-base mr-2" name="camera" :size="16" />
              <span :class="$style.uploadLabel" v-t="'profile.upload_photo'"></span>
            </label>
          </div>
        </div>
      </div>
    </template>

    <template #right>
      <div class="info flex">
        <div :class="$style.box" class="text-center">
          <p :class="$style.label" class="_text-3" v-t="'profile.total_credit'"></p>
          <p :class="$style.value" class="_text-2">{{ credits }}</p>
        </div>
        <div :class="$style.box" class="text-center">
          <p :class="$style.label" class="_text-3" v-t="'profile.available_tokens'"></p>
          <p :class="$style.value" class="_text-2">{{ tokens }}</p>
        </div>
      </div>
    </template>
  </Toolbar>
</template>

<script>

import { ref, watch } from 'vue';
import Toolbar from 'primevue/toolbar';

import { Avatar, Icon } from '../ui'

export default {
  components: {
    Toolbar,
    Avatar,
    Icon,
  },
  props: {
    name: String,
    credits: Number,
    tokens: Number,
    avatar: String,
  },
  emits: ['newAvatar'],
  setup(props, { emit }) {

    const avatarPreview = ref(props.avatar)

    function setAvatar(event) {
      const file = event.target.files[0]
      emit('newAvatar', file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        avatarPreview.value = this.result
      }
    }
    return {
      setAvatar,
      avatarPreview
    }
  }
}
</script>

<style lang="scss" module>
  .core {
    padding: 24px 18px;
    border-radius: $radius;
    border: 1px solid $text_color_line_gray
  }

  .box {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  .label {
    color: $text_color_gray;
    margin-bottom: 6px;
  }
  .upload {
    @include anim(opacity);
    color: $text_color_gray;
    fill: $text_color_gray;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  .uploadInput {
    position: absolute;
    left: -99999px;
    opacity: 0;
    visibility: hidden;
  }
</style>