<template>
  <Toolbar class="mt-4">
    <template #left>
      {{$t('paginator.info', { count, total })}}
    </template>

    <template #right class="flex align-items-center">
      <button :class="[page !== 1 ? $style.action : $style.disabled]" class="mr-1" @click="page += -1"><Icon name="chevron_left" /></button>
      <input  :class="$style.input" v-model.lazy="page">
      <span class="ml-3 mr-3" v-t="'paginator.separator'"></span>
      <input type="text" :class="$style.input" disabled :value="lastPage">
      <button :class="[page !== lastPage ? $style.action : $style.disabled]" class="ml-1" @click="page += 1"><Icon name="chevron_right" /></button>
    </template>
  </Toolbar>
</template>

<script>

import { ref, watch } from 'vue';
import Toolbar from 'primevue/toolbar';
import { useRoute, useRouter } from 'vue-router'

import { Icon } from '../../components/ui'

export default {
  components: {
    Toolbar,
    Icon,
  },
  props: {
    currentPage: Number,
    total: Number,
    lastPage: Number,
    perPage: Number,
    count: Number,
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const page = ref(parseInt(props.currentPage))
    function name(newValue) {
      const regx = /[^0-9]/g
      if (regx.test(newValue)) {
        newValue = newValue.replace(regx, '')
      }
      if (newValue < 1) {
        newValue = 1
        page.value = 1
        return
      }
      if (newValue > props.lastPage) {
        newValue = props.lastPage
        page.value = props.lastPage
        return
      }
      const query = {...route.query, ...{ page: newValue || 1 }}
      page.value = newValue
      router.push({ name: route.name, query: query })
    }
    watch(page, (newValue) => {
      if (props.currentPage !== newValue) {
        name(newValue)
      }
    })
    watch(() => props.currentPage, (newValue) => {
      name(parseInt(newValue))
    })
    return {
      page
    }
  }
}
</script>

<style lang="scss" module>
  .input {
    @include anim(color, border-color);
    border-radius: $radius-small;
    border-width: 0;
    width: 32px;
    height: 32px;
    text-align: center;
    border: 1px solid $text_color_line_gray;
    font-family: inherit;
    font-size: 15px;
    background-color: transparent;
  }

  .disabled,
  .action {
    @include anim(fill, opacity);
    fill: $text_color_light_gray;
  }

  .action {
    &:hover {
      fill: $text_color_black;
    }
  }
  .disabled {
    opacity: 0.5;
    cursor: default;
  }
</style>